import { css } from "../../theme";

const styles = css({
  height: "calc(100% - 200px)",

  ".container-join": {
    margin: "0 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },

  ".title-join": {
    fontWeight: "800",
    fontSize: "$3",
    color: "#0064E4",
    textTransform: "uppercase",
    textAlign: "center",
  },

  ".subtitle-join": {
    marginTop: "$3",
    lineHeight: "16px",
    textAlign: "center",
    fontWeight: "$ligth",
  },

  ".mobiles-selects-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
  },

  ".rules-screen": {
    marginTop: 30,
  },

  ".item-rule": {
    marginBottom: 12,
    fontWeight: "$ligth",

    "&:last-child": {
      marginBottom: 0,
    },
  },

  ".utlrabold": {
    fontWeight: "$medium",
  },

  ".action-container": {
    textAlign: "center",
    marginTop: "$5",

    position: "fixed",
    left: 0,
    right: 0,

    bottom: 0,
    padding: "12px 0",

    "backdrop-filter": "blur(2px)",
    "-webkit-backdrop-filter": "blur(2px)",
    "-o-backdrop-filter": "blur(2px)",
  },
});

export default styles;
