import React from "react";
import { globalCss } from "@stitches/react";

// themes
import { THEMES_LIST } from "../theme";

const ThemeContext = React.createContext();

const globalStyles = globalCss({
  html: {
    margin: 0,
    fontFamily: "$optimistic-display",
    fontWeight: "$medium",
    background: "$background-page",
  },

  ":root": {
    fontSize: "14px",
  },

  // scroll bars
  "::-webkit-scrollbar": {
    width: 4,
    height: 4,
    background: "transparent",
  },
  /* Track */
  "::-webkit-scrollbar-track ": {
    boxShadow: "inset 0 0 5px #FFF",
  },
  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: "$primary-200",
    borderRadius: 10,
  },
  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "$primary-300",
  },
});

const localstorage_key = "theme_application";

const ThemeProvider = (props) => {
  const [currentTheme, setCurrentTheme] = React.useState("default");
  const html = document.documentElement;
  const theme_application = localStorage.getItem(localstorage_key);

  React.useEffect(() => {
    if (theme_application) {
      changeTheme(JSON.parse(theme_application));
    }
  }, []);

  html.classList.add(THEMES_LIST.default);

  const changeTheme = (value) => {
    html.className = "";

    // eslint-disable-next-line no-prototype-builtins
    if (THEMES_LIST.hasOwnProperty(value)) {
      html.classList.add(THEMES_LIST[value]);
      setCurrentTheme(value);
    } else {
      html.classList.add(THEMES_LIST.default);
      setCurrentTheme("default");
    }
  };

  globalStyles();

  const values = { changeTheme, theme: currentTheme };

  return (
    <ThemeContext.Provider value={values} {...props}></ThemeContext.Provider>
  );
};

const useTheme = () => React.useContext(ThemeContext);

export { ThemeProvider, useTheme };
