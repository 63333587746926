import { css } from "../../theme";

const styles = css({
  maxWidth: 103,
  flex: 1,

  border: "1px solid transparent",

  borderRadius: "$medium",
  position: "relative",
  padding: "$5 $3",
  marginRight: "$3",
  boxShadow: "$center-small",
  boxSizing: "border-box",
  cursor: "pointer",
  background: "transparent",

  "&:last-child": {
    marginRight: 0,
  },

  ".phone-content": {
    display: "flex",
    justifyContent: "center",
  },

  ".label-mobile": {
    marginTop: 20,
    textAlign: "center",
    fontWeight: "600",
    fontSize: "$4",
    color: "#0064E4",
  },

  variants: {
    selected: {
      true: {
        borderColor: "#0064E4",
      },
    },
    disabled: {
      true: {
        opacity: 0.5,

        ".phone-content": {
          //opacity: 0.4,
        },

        ".label-mobile": {
          color: "$text-disabled",
        },
      },
    },
    onlyRead: {
      true: {
        display: "inline-block",
        maxWidth: 200,
        width: "100%",
        height: 200,
        margin: 0,

        paddingTop: 55,

        ".label-mobile": {
          marginTop: "$5",
        },
      },
    },
  },
});

export default styles;
