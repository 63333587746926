import React from "react";
import styles from "./style";
import { useTranslation } from "react-i18next";

const Language = () => {
  const [langue, setLangue] = React.useState("fr");

  const { t, i18n } = useTranslation();

  const handleLangue = (value) => {
    i18n.changeLanguage(value);
    setLangue(value);
  };

  return (
    <div className={styles({ selected: langue })}>
      <div className="content-language">
        <span className="langue-fr" onClick={() => handleLangue("fr")}>
          FR
        </span>{" "}
        |{" "}
        <span className="langue-en" onClick={() => handleLangue("en")}>
          EN
        </span>
      </div>
    </div>
  );
};

export default Language;
