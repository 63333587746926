export default {
  translation: {
    title: "PASSION IS MEANT <br />TO BE SHARED",
    subtitle:
      "Discover the only film of the <span class='utlrabold'>RWC2023</span> that can be watched on three mobile screens simultaneously.",
    step1:
      "<span class='utlrabold'>1 - </span>Place <span class='utlrabold'>3 phones</span> side by side.",
    step2:
      "<span class='utlrabold'>2 - </span>Choose the position of <span class='utlrabold'>each phone</span>.",
    step3:
      "<span class='utlrabold'>3 - </span>Ensure that the <span class='utlrabold'>central phone’s sound is turned on</span>.",
    step4: "<span class='utlrabold'>4 - </span>Start the video",
    choose_film: "Choose the screen",
    left: "Left",
    center: "Center",
    right: "Right",
    wait_play: "Waiting",
    play_video: "Start the video",
    position_left: "Left Side",
    position_center: "Center Side",
    position_right: "Right Side",
  },
};
