import { createStitches } from "@stitches/react";

// themes
import { ligth } from "./ligth";
import { dark } from "./dark";

// utils
import { hexToRGBA } from "../utils/functions";

const shadow_color = "#5374FD";

export const { css, createTheme } = createStitches({
  theme: {
    colors: {
      // colors app
      "primary-100": "#F9FAFE",
      "primary-200": "#D4DDFF",
      "primary-300": "#A3B5FF",
      "primary-400": "#7B95FF",
      "primary-500": "#5374FD",
      "primary-600": "#4949FC",
      "primary-700": "#26267E",
      "primary-800": "#0E0E2E",
      "primary-900": "#000021",

      "secondary-100": "#F6EA65",
      "secondary-200": "#F2E205",
      "secondary-300": "#F2CF00",
      "secondary-400": "#F1B700",
      "secondary-500": "#F19E00",
      "secondary-600": "#FF7A00",
      "secondary-700": "#EF7300",
      "secondary-800": "#864000",
      "secondary-900": "#5E2D00",

      "tertiary-100": "#00C3BB",
      "tertiary-200": "#00B3A9",
      "tertiary-300": "#00A497",
      "tertiary-400": "#009689",
      "tertiary-500": "#008677",
      "tertiary-600": "#007567",
      "tertiary-700": "#005952",
      "tertiary-800": "#00413C",
      "tertiary-900": "#002623",

      "quaternary-100": "#D177FF",
      "quaternary-200": "#B13CED",
      "quaternary-300": "#A40CF1",
      "quaternary-400": "#A002F0",
      "quaternary-500": "#7201AB",
      "quaternary-600": "#580184",
      "quaternary-700": "#460A64",
      "quaternary-800": "#300446",
      "quaternary-900": "#1C0229",

      // Gray colors - neutres
      "gray-100": "#FFFFFF",
      "gray-200": "#F7F7F7",
      "gray-300": "#F2F2F2",
      "gray-400": "#D8D8D8",
      "gray-500": "#9B9B9B",
      "gray-600": "#353535",
      "gray-700": "#1F1F23",

      white: "#FFFFFF",
      black: "#000000",

      // base
      "success-base": "#00B3A9",
      "success-light": hexToRGBA("#00B3A9", 0.1),
      "success-low": "",

      "error-base": "#DE0712",
      "error-light": hexToRGBA("#DE0712", 0.1),
      "error-low": "",

      "warning-base": "#F19E00",
      "warning-light": hexToRGBA("#F19E00", 0.1),
      "warning-low": "",

      "information-base": "#5374FD",
      "information-light": hexToRGBA("#5374FD", 0.1),
      "information-low": "",

      // Components
      "text-title": "",
      "text-base": "$gray-600",
      "text-disabled": "$gray-500",
      "text-support": "",
      "disabled-date": "$gray-500",

      disabled: "$gray-400",
      "disabled-border": "$gray-300",
      "disabled-background": "$gray-300",

      placeholder: "$gray-400",
      "inputs-icons": "",
      "hover-color": "$primary-200",
      "hover-color-secondary": "$primary-200",
      "aria-selected": "$primary-300",

      "color-background": "$white",
      "shadow-color": "#4949FC",

      // backgrounds
      "background-page": "$primary-100",
      "background-primary": "$white",
      "background-secondary": "$white",
      "background-tertiary": "$primary-100",
      "background-header": "$white",
      "search-header": "$primary-100",
      "background-dashboard-card": "$primary-200",
      "border-card-primary": "$primary-200",
      "background-line-table-dark": "$primary-100",

      "tr-even": "$primary-100",
      "td-odd": "$white",
      "border-td": "$gray-400",

      // Full Calendar
      "fullcalendar-background": "$white",
      "fullcalendar-non-business": hexToRGBA("#D8D8D8", 0.5),
      "fullcalendar-past-days": "$gray-200",
      "fullcalendar-weekend": "$gray-300",
    },
    space: {
      1: "4px",
      2: "8px",
      3: "12px",
      4: "16px",
      5: "20px",
      6: "24px",
      7: "28px",
      8: "32px",
      9: "40px",
      10: "48px",
      11: "64px",
      12: "80px",
    },
    fontSizes: {
      1: "2.58rem", //36
      2: "1.79rem", //25
      3: "1.45rem", //20
      4: "1.15rem", //16
      5: "1rem", //14
      6: "0.85rem", //12
      7: "0.71rem", // 10
    },
    sizes: {
      1: "25px",
      2: "33px",
      3: "41px",
    },
    fonts: {
      "gotham-narrow": "Gotham Narrow, arial, monospace",
      "optimistic-display": "Optimistic Display, arial, monospace",
    },
    fontWeights: {
      ultra: 800,
      bold: 600,
      medium: 500,
      ligth: 200,
    },
    lineHeights: {},
    letterSpacings: {},
    borderWidths: {},
    borderStyles: {},
    radii: {
      small: "4px",
      medium: "6px",
      large: "12px",
      xlarge: "24px",
    },
    shadows: {
      "top-xs": `0px -2px 4px 0px ${hexToRGBA(shadow_color, 0.1)}`,
      "top-small": `0px -2px 6px 0px ${hexToRGBA(shadow_color, 0.2)}`,
      "top-medium": `0px -2px 10px 0px ${hexToRGBA(shadow_color, 0.2)}`,
      "top-large": `0px -2px 10px 0px ${hexToRGBA(shadow_color, 0.3)}`,

      "center-xs": `0px 0px 4px 0px ${hexToRGBA(shadow_color, 0.1)}`,
      "center-small": `0px 0px 6px 0px ${hexToRGBA(shadow_color, 0.2)}`,
      "center-medium": `0px 0px 10px 0px ${hexToRGBA(shadow_color, 0.2)}`,
      "center-large": `0px 0px 10px 0px ${hexToRGBA(shadow_color, 0.3)}`,

      "bottom-xs": `0px 2px 4px 0px ${hexToRGBA(shadow_color, 0.1)}`,
      "bottom-small": `0px 2px 6px 0px ${hexToRGBA(shadow_color, 0.2)}`,
      "bottom-medium": `0px 2px 10px 0px ${hexToRGBA(shadow_color, 0.2)}`,
      "bottom-large": `0px 2px 10px 0px ${hexToRGBA(shadow_color, 0.3)}`,
    },
    zIndices: {},
    transitions: {},
    media: {
      bp1: "(min-width: 640px)",
      bp2: "(min-width: 768px)",
      bp3: "(min-width: 1024px)",
    },
  },
});

const ligthTheme = createTheme("ligth", ligth);

const darkTheme = createTheme("dark", dark);

export const THEMES_LIST = {
  default: ligthTheme,
  light: ligthTheme,
  dark: darkTheme,
};

// fontSizes: {
//   1: "36px",
//   2: "25px",
//   3: "20px",
//   4: "16px",
//   5: "14px",
//   6: "12px",
//   7: "10px",
// },
