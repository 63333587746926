import { hexToRGBA } from "../utils/functions";

const shadow_color = "#FFFFFF";

export const dark = {
  colors: {
    "color-background": "$black",

    "background-page": "$black",
    "background-primary": "$gray-700",
    "background-secondary": "$black",
    "background-tertiary": "$gray-700",
    "background-header": "$black",
    "background-dashboard-card": "$black",
    "search-header": "$black",
    "hover-color": "$gray-700",
    "hover-color-secondary": "$black",
    "border-card-primary": "$gray-700",
    "background-line-table-dark": "$gray-600",

    "fullcalendar-background": "$black",
    "fullcalendar-past-days": "$gray-600",
    "fullcalendar-weekend": "$gray-600",

    "tr-even": "$gray-600",
    "td-odd": "$black",
    "border-td": "$gray-700",

    "text-base": "$white",
    "text-disabled": "$white",
    //"text-disabled": "$gray-500",

    "disabled-border": "$gray-500",
    "disabled-background": "$gray-500",
  },
  shadows: {
    "top-xs": `0px -2px 4px 0px ${hexToRGBA(shadow_color, 0.1)}`,
    "top-small": `0px -2px 6px 0px ${hexToRGBA(shadow_color, 0.1)}`,
    "top-medium": `0px -2px 10px 0px ${hexToRGBA(shadow_color, 0.1)}`,
    "top-large": `0px -2px 10px 0px ${hexToRGBA(shadow_color, 0.1)}`,

    "center-xs": `0px 0px 4px 0px ${hexToRGBA(shadow_color, 0.1)}`,
    "center-small": `0px 0px 6px 0px ${hexToRGBA(shadow_color, 0.1)}`,
    "center-medium": `0px 0px 10px 0px ${hexToRGBA(shadow_color, 0.1)}`,
    "center-large": `0px 0px 10px 0px ${hexToRGBA(shadow_color, 0.1)}`,

    "bottom-xs": `0px 2px 4px 0px ${hexToRGBA(shadow_color, 0.1)}`,
    "bottom-small": `0px 2px 6px 0px ${hexToRGBA(shadow_color, 0.1)}`,
    "bottom-medium": `0px 2px 10px 0px ${hexToRGBA(shadow_color, 0.1)}`,
    "bottom-large": `0px 2px 10px 0px ${hexToRGBA(shadow_color, 0.1)}`,
  },
};
