import React from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";

import CardMobile from "../../components/CardMobile";
import Button from "../../components/Button";

export default function JoinScreen({ onSelect, availables }) {
  const [selected, setSelected] = React.useState("");

  const { t } = useTranslation();

  const handleSelectColor = (position) => setSelected(position);

  const handleJoindre = () => {
    if (selected) onSelect(selected);
  };

  return (
    <div className={styles({ selected: selected !== "" })}>
      <div className="container-join">
        <div className="main-join">
          <h2
            className="title-join"
            dangerouslySetInnerHTML={{
              __html: t("title"),
            }}
          />

          <p
            className="subtitle-join"
            dangerouslySetInnerHTML={{
              __html: t("subtitle"),
            }}
          />

          <div className="mobiles-selects-container">
            <CardMobile
              position="left"
              onClick={() => handleSelectColor("left")}
              selected={selected === "left"}
            />

            <CardMobile
              position="center"
              onClick={() => handleSelectColor("center")}
              selected={selected === "center"}
            />

            <CardMobile
              position="right"
              onClick={() => handleSelectColor("right")}
              selected={selected === "right"}
            />
          </div>

          <div className="rules-screen">
            <p
              className="item-rule"
              dangerouslySetInnerHTML={{
                __html: t("step1"),
              }}
            />
            <p
              className="item-rule"
              dangerouslySetInnerHTML={{
                __html: t("step2"),
              }}
            />
            <p
              className="item-rule"
              dangerouslySetInnerHTML={{
                __html: t("step3"),
              }}
            />
            <p
              className="item-rule"
              dangerouslySetInnerHTML={{
                __html: t("step4"),
              }}
            />
          </div>
        </div>

        <div className="action-container">
          <Button
            label={t("choose_film")}
            selected={selected !== ""}
            onClick={handleJoindre}
          />
        </div>
      </div>
    </div>
  );
}
