import { css } from "../../theme";

const styles = css({
  textAlign: "right",
  // padding: "25px 40px",

  variants: {
    selected: {
      fr: {
        ".langue-fr": {
          color: "rgb(0, 100, 228)",
        },
      },
      en: {
        ".langue-en": {
          color: "rgb(0, 100, 228)",
        },
      },
    },
  },
});

export default styles;
