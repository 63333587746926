import React from "react";
import styles from "./style";
import Language from "../Language";

// logo
const logo = require("../../assets/img/logo.png");

export default function Header(props) {
  return (
    <div className={styles({})}>
      <div className="logo-container">
        <img src={logo} className="logo-img" alt="logo meta" />
      </div>
      <Language />
    </div>
  );
}
