import React from "react";
import styles from "./style";

import CardMobile from "../../components/CardMobile";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";

const preview = require("../../assets/img/video-preview.jpg");
const play_icon = require("../../assets/img/play.png");

export default function VideoScreen({
  position,
  isPlay,
  videos,
  onPlay,
  playAt,
  allLogged,
}) {
  const videoRef = React.useRef(null);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (isPlay) {
      // setTimeout(onPlayVideo, playAt - Date.now());
      onPlayVideo();
    }
  }, [isPlay, playAt]);

  const onPlayVideo = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    }

    videoRef.current.play();
  };

  return (
    <div className={styles({ isPlay })}>
      <div className="main-video">
        {!isPlay ? (
          <div className="mobile-position">
            <CardMobile position={position} onlyRead />
          </div>
        ) : null}

        <video
          className="video-player"
          ref={videoRef}
          src={videos[position]}
          width="100%"
          height="auto"
          controls="controls"
          preload="auto"
          onEnded={() => onPlay(false)}
        />
      </div>

      {position !== "left" && !isPlay && (
        <p className="waiting-label">{t("wait_play")}</p>
      )}

      {position === "left" && !isPlay && (
        <div className="action-container">
          <Button
            label={t("play_video")}
            onClick={() => onPlay(true)}
            selected
          />
        </div>
      )}
    </div>
  );
}

const Play = ({ onClick }) => {
  return (
    <div className="play-icon-container" onClick={onClick}>
      <img src={play_icon} className="play-icon" alt="play" />
    </div>
  );
};
