import { css } from "../../theme";

const styles = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px 40px 40px",

  ".logo-container": {
    // display: "flex",
    //  justifyContent: "center",
    // padding: "0 0 55px",
  },

  ".logo-img": {
    width: 175,
  },
});

export default styles;
