import React from "react";
import styles from "./style";
import Header from "../Header";

export default function Screen({ children }) {
  return (
    <div className={styles({})}>
      <Header />
      {children}
    </div>
  );
}
