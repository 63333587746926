import React from "react";
import styles from "./style";

const Button = ({ label, selected, onClick }) => {
  return (
    <div className={styles({ selected })} onClick={onClick}>
      <span className="button-label">{label}</span>
    </div>
  );
};

export default Button;
