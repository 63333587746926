import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import EN from "../assets/locales/en-EN";
import FR from "../assets/locales/fr-FR";

// the translations
const resources = {
  en: EN,
  fr: FR,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    format: function (value, format, lng) {
      if (format === "uppercase") return value.toUpperCase();
      if (format === "lowercase") return value.toLowerCase();
      //if (format === "bold") return value.bold()
      return value;
    },
  },
  react: {
    bindI18n: "languageChanged",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    useSuspense: false,
  },
});

export default i18n;
