import React from "react";
import io from "socket.io-client";

import { ThemeProvider } from "./providers/ThemeProvider";
import { I18nextProvider } from "react-i18next";

import i18n from "./configs/i18n";
// components
import Screen from "./components/Screen";

// screens
import JoinScreen from "./screens/JoinScreen";
import VideoScreen from "./screens/VideoScreen";

//const socket = io.connect("http://localhost:3600");
const socket = io.connect();

// videos
const blue = "https://lapassioncasepartage.com/gauche.mp4";
const yellow = "https://lapassioncasepartage.com/centre.mp4";
const green = "https://lapassioncasepartage.com/droite.mp4";

const videos = { left: blue, center: yellow, right: green };

const App = () => {
  return (
    <ThemeProvider>
      <I18nextProvider i18n={i18n}>
        <Main />
      </I18nextProvider>
    </ThemeProvider>
  );
};

function Main() {
  const [isPlay, setIsPlay] = React.useState(false);
  const [playAt, setPlayAt] = React.useState();
  const [availablesPositions, setAvailablesPositions] = React.useState([]);

  const [selectedPosition, setSelectedPosition] = React.useState();
  //const [connectedsNbr, setConnectedsNbr] = React.useState(1);

  React.useEffect(() => {
    // socket.on("connectedUsers", (users) => {
    //   setConnectedsNbr(users);
    // });

    socket.on("availables", (availables) => {
      setAvailablesPositions(availables);
    });

    socket.on("choosen", (color) => {
      setAvailablesPositions((prevColors) =>
        prevColors.filter((c) => c !== color)
      );
    });

    socket.on("released", (color) => {
      setAvailablesPositions((prevColors) => [...prevColors, color]);
    });

    socket.on("play_video", (data) => {
      if (data) {
        setIsPlay(true);
        setPlayAt(data.time);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handlePlay = async (value) => {
    if (value) {
      const data = {
        time: getTime(),
      };

      setPlayAt(data.time);
      await socket.emit("to_play_all", data);
    }

    setIsPlay(value);
  };

  const handleJoin = async (color) => {
    if (color) {
      const data = {
        color: color,
      };

      await socket.emit("join", data);
      setSelectedPosition(color);
    }
  };

  return (
    <div className="container-app">
      <Screen>
        {!selectedPosition ? (
          <JoinScreen onSelect={handleJoin} availables={availablesPositions} />
        ) : (
          <VideoScreen
            videos={videos}
            position={selectedPosition}
            isPlay={isPlay}
            onPlay={handlePlay}
            playAt={playAt}
            allLogged={true}
            //allLogged={availablesPositions.length === 0}
          />
        )}
      </Screen>
    </div>
  );
}

const getTime = () => Date.now() + 2000;

export default App;
