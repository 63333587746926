import { css } from "../../theme";

const styles = css({
  display: "inline-block",
  height: 30,
  padding: "4px 40px",

  border: "1px solid #0064E4",
  borderRadius: "999px",
  background: "#0064E4",

  lineHeight: "30px",
  fontSize: "16px",
  cursor: "pointer",

  ".button-label": {
    color: "$white",
    fontWeight: "$bold",
    textTransform: "uppercase",
  },

  variants: {
    selected: {
      false: {
        background: "$disabled",
        borderColor: "$disabled",
        cursor: "not-allowed",

        ".button-label": {
          color: "$text-disabled",
        },
      },
    },
  },
});

export default styles;
