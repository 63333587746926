export default {
  translation: {
    title: "La passion, ça se partage",
    subtitle:
      "Découvrez le seul film de la <span class='utlrabold'>Coupe du Monde de Rugby 2023</span>, qui se regarde sur trois écrans de mobiles en simultané.",
    step1:
      "<span class='utlrabold'>1 - </span> Placez  <span class='utlrabold'>3 téléphones</span> les uns à côté des autres.",
    step2:
      "<span class='utlrabold'>2 - </span> Choisissez la position de <span class='utlrabold'>chaque téléphone</span>.",
    step3:
      "<span class='utlrabold'>3 - </span> Pensez à <span class='utlrabold'>activer le son du téléphone au centre</span>.",
    step4: "<span class='utlrabold'>4 - </span> Lancez la vidéo.",
    choose_film: "Choisir l'écran",
    left: "Gauche",
    center: "Centre",
    right: "Droite",
    wait_play: "Attente du lancement",
    play_video: "Lancer la video",
    position_left: "Position Gauche",
    position_center: "Position Centre",
    position_right: "Position Droite",
  },
};
