import React from "react";
import styles from "./style";
import { useTranslation } from "react-i18next";

// img
const phone = require("../../assets/img/i-phone.png");

// labe

export default function CardMobile({
  position,
  selected,
  disabled = false,
  onlyRead = false,
  onClick,
}) {
  const { t } = useTranslation();

  const label = {
    left: t("left"),
    center: t("center"),
    right: t("right"),
  };

  const handleClick = () => {
    if (!disabled && !onlyRead) onClick();
  };

  const _label = onlyRead ? t(`position_${position}`) : label[position];

  return (
    <div
      className={styles({ position, selected, disabled, onlyRead })}
      onClick={handleClick}>
      <div className="phone-content">
        <img src={phone} className="phone-img" alt="logo meta" />
      </div>
      <p className="label-mobile">{_label}</p>
    </div>
  );
}
