import { css } from "../../theme";

const styles = css({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",

  //height: "calc(100vh - 160px)",
  height: "calc(100vh - 250px)",

  ".main-video": {
    height: "100%",

    display: "flex",
    alignItems: "center",
  },

  ".waiting-label": {
    textAlign: "center",
    margin: "30px 0",
    fontSize: "16px",
  },

  ".mobile-position": {
    display: "flex",
    justifyContent: "center",
    width: 200,
  },
  ".video-player": {},

  ".action-container": {
    textAlign: "center",

    position: "fixed",
    left: 0,
    right: 0,

    bottom: 0,
    padding: "12px 0",
  },

  variants: {
    isPlay: {
      false: {
        ".video-player": {
          // position: "absolute",
          // zIndex: -1,
          // opacity: 0,
          // top: "-100%",
          // left: "-100%",
          display: "none",
        },
      },
      true: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
      },
    },
  },
});

export default styles;
// const styles = {
//   container: {
//     background: "#F9FAFE",
//   },
//   content: {
//     display: "flex",
//     alignItems: "center",
//     height: "100vh",
//   },
//   staticContainer: {
//     position: "relative",
//     flex: "1 1 auto",
//   },
//   static: {
//     height: "50vh",
//     margin: "10px 20px",
//     borderRadius: "6px",
//   },
//   waiting: {
//     textAlign: "center",
//     marginBottom: "6px",
//     fontSize: "28px",
//     position: "absolute",
//     width: "100%",
//     top: "50%",
//     transform: "translate3d(0, -50%, 0)",
//   },

//   counter: {
//     position: "absolute",
//     width: "100%",
//     height: "100%",

//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   playContainer: {
//     position: "absolute",
//     top: "50%",
//     transform: "translate3d(0, -50%, 0)",

//     width: "100%",
//     height: "100%",

//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   playContent: {
//     border: "2px solid #353535",
//     width: 80,
//     height: 80,
//     borderRadius: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     cursor: "pointer",
//   },
//   iconPlay: {
//     display: "inline-block",
//     height: 0,
//     width: 0,
//     borderTop: "25px solid transparent",
//     borderBottom: "25px solid transparent",
//     borderLeft: "43px solid #353535",
//     transform: "translate3d(5px, 0, 0)",
//     cursor: "pointer",
//   },
//   notplayed: {
//     position: "absolute",
//     zIndex: -1,
//     opacity: 0,
//   },
// };
